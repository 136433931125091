import * as React from 'react'
import ArrowRightSmall from 'wix-ui-icons-common/on-stage/ArrowRightSmall'
import classNames from 'classnames'
import {useTranslation} from '@wix/yoshi-flow-editor'
import {Summary} from '../summary'
import {useEventDateInformation} from '../../../../../../commons/hooks/dates'
import s from './tickets.scss'
import {Ticket} from './ticket'
import {EmptyState} from './empty-state'
import {EventImage} from './event-image'
import {TicketsProps} from '.'

export const Tickets = ({
  showTickets,
  selectedSeats,
  title,
  image,
  location,
  eventId,
  onBackClick,
  onRemoveClick,
  onCheckoutClick,
}: TicketsProps) => {
  const {t} = useTranslation()
  const {fullDate} = useEventDateInformation(eventId)

  return (
    <div className={classNames(s.container, {[s.showTickets]: showTickets, [s.hideTickets]: !showTickets})}>
      <div>
        <div className={s.header}>
          <div className={s.backButton} onClick={onBackClick}>
            <ArrowRightSmall /> <div>{t('seatings_backToMap')}</div>
          </div>
        </div>
        {!selectedSeats.length ? (
          <EmptyState />
        ) : (
          <>
            <div className={s.event}>
              {image ? <EventImage image={image} /> : null}
              <div>
                <div className={s.name}>{title}</div>
                <div className={s.date}>
                  {fullDate}, {location}
                </div>
              </div>
            </div>
            <div className={s.tickets}>
              <div className={s.title}>{t('seatings_yourTickets', {count: selectedSeats.length})}</div>
              <div className={s.list}>
                {selectedSeats.map(selectedTicket => (
                  <Ticket {...selectedTicket} onRemoveClick={onRemoveClick} />
                ))}
              </div>
            </div>
          </>
        )}
      </div>
      {selectedSeats.length ? (
        <div className={s.summaryContainer}>
          <Summary onCheckoutClick={onCheckoutClick} />
        </div>
      ) : null}
    </div>
  )
}
