import {SeatingPlan} from '@wix/ambassador-seating-plans/types'
import {getScaledViewBox, round} from '@wix/seating-common-components'
import {useState, PointerEventHandler} from 'react'

const getInitialViewBox = (plan: SeatingPlan) => {
  const calculatedInitialViewBox = plan.elements.reduce(
    ({width, height, x, y}, {config = {}}) => {
      const maxX = Number(config.x) + Number(config.width)
      const maxY = Number(config.y) + Number(config.height)

      if (Number(config.x) < x) {
        x = Number(config.x)
      }

      if (Number(config.y) < y) {
        y = Number(config.y)
      }

      if (maxX > width) {
        width = maxX
      }

      if (maxY > height) {
        height = maxY
      }

      return {width: Math.ceil(width), height: Math.ceil(height), x: Math.floor(x), y: Math.floor(y)}
    },
    {width: 0, height: 0, x: 99999, y: 99999},
  )
  const offset = 20
  const {width, height, x, y} = calculatedInitialViewBox

  return {
    width: width - x + offset,
    height: height - y + offset,
    x: x - offset,
    y: y - offset,
  }
}

const getPointFromEvent = event => {
  const point = {x: 0, y: 0}

  if (event.targetTouches) {
    point.x = event.targetTouches[0].clientX
    point.y = event.targetTouches[0].clientY
  } else {
    point.x = event.clientX
    point.y = event.clientY
  }

  return point
}

interface ChangeSvgViewBoxArgs {
  deltaX?: number
  deltaY?: number
  deltaScale?: number
}

export const useViewBox = (plan: SeatingPlan = {}) => {
  const {width, height, x, y} = getInitialViewBox(plan)
  const [viewBox, setViewBox] = useState(`${x} ${y} ${width} ${height}`)
  const [startCoordinates, setStartCoordinates] = useState({x: 0, y: 0})
  const [noCompensationCoordinates, setNoCompensationCoordinates] = useState({x, y})
  const [zoom, setZoom] = useState(1)

  const changeSvgViewBox = ({deltaX = 0, deltaY = 0, deltaScale = 0}: ChangeSvgViewBoxArgs) => {
    const {x: noCompensationX, y: noCompensationY} = noCompensationCoordinates

    let minX = noCompensationX - deltaX
    let minY = noCompensationY - deltaY
    const scale = round(zoom + deltaScale, 0.1)

    // Moving view
    const {x: centeringX, y: centeringY, width: centeringWidth, height: centeringHeight} = getScaledViewBox({
      scale,
      initialWidth: width,
      initialHeight: height,
    })

    setNoCompensationCoordinates({x: minX, y: minY})
    setZoom(scale)

    minX -= centeringX
    minY -= centeringY

    setViewBox(`${minX} ${minY} ${centeringWidth} ${centeringHeight}`)
  }

  const onPointerDown: PointerEventHandler<HTMLDivElement> = event => {
    const pointerPosition = getPointFromEvent(event)
    setStartCoordinates({x: pointerPosition.x, y: pointerPosition.y})
  }

  const onPointerMove: PointerEventHandler<HTMLDivElement> = event => {
    event.preventDefault()

    const {x: mouseX, y: mouseY} = getPointFromEvent(event)
    const {x: startX, y: startY} = startCoordinates
    const fixedZoom = round(zoom)

    const dx = mouseX - startX
    const dy = mouseY - startY

    changeSvgViewBox({
      deltaX: round(dx / fixedZoom, 0.00001),
      deltaY: round(dy / fixedZoom, 0.00001),
    })

    setStartCoordinates({x: mouseX, y: mouseY})
  }

  const onPointerUp = () => setStartCoordinates({x: 0, y: 0})

  return {
    viewBox,
    zoom,
    changeSvgViewBox,
    onPointerDown,
    onPointerMove,
    onPointerUp,
  }
}
