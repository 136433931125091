import {getEventTitle, getEventMainImage, getFormattedFullLocation, getEventId} from '@wix/wix-events-commons-statics'
import {withTranslation} from '@wix/yoshi-flow-editor'
import {DetailsPageAppProps} from '../../../app/interfaces'
import {connect} from '../../../runtime-context/context'
import {TicketsRuntimeProps, TicketsOwnProps} from './interfaces'
import {Tickets as Presentation} from './tickets'

const mapRuntime = ({state: {event}}: DetailsPageAppProps): TicketsRuntimeProps => ({
  eventId: getEventId(event),
  title: getEventTitle(event),
  image: getEventMainImage(event),
  location: getFormattedFullLocation(event),
})

export const Tickets = connect<TicketsOwnProps, TicketsRuntimeProps>(mapRuntime)(withTranslation()(Presentation))
export * from './interfaces'
