import * as React from 'react'
import {TicketInfo} from '../../ticket-info'
import s from './ticket.scss'
import {TicketProps} from '.'

export const Ticket = ({onRemoveClick, ...selectedSeat}: TicketProps) => (
  <div className={s.ticket}>
    <div className={s.color} style={{backgroundColor: selectedSeat.color}} />
    <TicketInfo {...selectedSeat} onRemoveClick={() => onRemoveClick(selectedSeat.id, selectedSeat.ticketId)} />
  </div>
)
