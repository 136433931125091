import classNames from 'classnames'
import React from 'react'
import {Tooltip} from 'wix-ui-tpa/Tooltip'
import {Badge} from '../../../badge'
import c from '../../../classnames.scss'
import {QuantityPicker} from '../quantity-picker'
import s from './mobile-ticket.scss'
import {Price} from './price'
import {TicketDescription} from './ticket-description'
import {SoldOutProps, TicketProps} from '.'

export const Ticket: React.FC<TicketProps> = ({
  ticket,
  selectedQuantity,
  availableTicketsQuantity,
  registrationClosed,
  onChange,
  t,
  isMobile,
  maxTicketsPerReservation,
  newMobileCheckout,
}) => {
  const {id, limitPerCheckout, name, description} = ticket
  const showTooltip = selectedQuantity > 0 && availableTicketsQuantity === 0

  return (
    <div key={id} className={classNames(s.ticket, c.evTextColor, c.evTextFont)} data-hook="ticket">
      {!limitPerCheckout ? <SoldOut t={t} /> : null}
      <div
        className={classNames(s.name, c.evTicketNameColor, c.evTicketNameFont)}
        data-hook="ticket-name"
        aria-label={`${t('ticketsThankYou.ticketName')}: ${name}`}
      >
        {name}
      </div>
      <Price t={t} ticket={ticket} />
      <div className={classNames({[s.inner]: !newMobileCheckout, [s.mobileCheckoutInner]: newMobileCheckout})}>
        <Tooltip
          className={s.tooltip}
          disabled={!showTooltip}
          shown={showTooltip}
          moveBy={{x: 10, y: 0}}
          minWidth={127}
          placement="right"
          content={
            maxTicketsPerReservation === 1
              ? t('ticketLimitPerReservationReached_singular')
              : t('ticketLimitPerReservationReached', {count: maxTicketsPerReservation})
          }
        >
          <div className={s.quantity}>
            <QuantityPicker
              quantity={selectedQuantity}
              min={0}
              max={Math.min(availableTicketsQuantity + selectedQuantity, limitPerCheckout)}
              disabled={!limitPerCheckout || registrationClosed}
              onChange={value => onChange({ticketId: id, count: value})}
              t={t}
              isMobile={isMobile}
            />
          </div>
        </Tooltip>
      </div>
      {description ? (
        <div className={s.description}>
          <TicketDescription ticketDefId={id} description={description} />
        </div>
      ) : null}
      <div
        className={classNames(c.evTicketDividerColor, {
          [s.divider]: !newMobileCheckout,
          [s.mobileCheckoutDivider]: newMobileCheckout,
        })}
      />
    </div>
  )
}

const SoldOut: React.FC<SoldOutProps> = ({t}) => (
  <div className={s.soldOut}>
    <Badge>{t('mobile.ticketsPicker.soldOut')}</Badge>
  </div>
)
