import * as React from 'react'
import Plus from 'wix-ui-icons-common/on-stage/Plus'
import Minus from 'wix-ui-icons-common/on-stage/Minus'
import {IconButton} from 'wix-ui-tpa/IconButton'
import classNames from 'classnames'
import s from './controls.scss'
import {ControlsProps} from '.'

export const Controls = ({
  selectedSeat,
  selectedSeats,
  zoomOutDisabled,
  zoomInDisabled,
  onZoomIn,
  onZoomOut,
}: ControlsProps) => (
  <div
    className={classNames(s.controls, {
      [s.controlsWithSelectedTicket]: selectedSeat,
      [s.controlsWithSummary]: !selectedSeat && selectedSeats.length,
    })}
  >
    <IconButton
      className={s.control}
      disabled={zoomInDisabled}
      onClick={zoomInDisabled ? undefined : onZoomIn}
      icon={<Plus />}
    />
    <IconButton
      className={s.control}
      disabled={zoomOutDisabled}
      onClick={zoomOutDisabled ? undefined : onZoomOut}
      icon={<Minus />}
    />
  </div>
)
