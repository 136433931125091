import * as React from 'react'
import classNames from 'classnames'
import {Summary} from '../summary'
import {TicketInfo} from '../ticket-info'
import s from './footer.scss'
import {FooterProps} from '.'

export const Footer = ({
  selectedSeat,
  selectedSeats,
  onAddClick,
  onCloseClick,
  onTicketsClick,
  onCheckoutClick,
}: FooterProps) => (
  <div
    className={classNames(s.footer, {
      [s.footerWithSelectedTicket]: selectedSeat,
      [s.footerWithSummary]: !selectedSeat && selectedSeats.length,
    })}
  >
    {selectedSeat ? (
      <TicketInfo {...selectedSeat} onAddClick={onAddClick} onCloseClick={onCloseClick} />
    ) : (
      <Summary onTicketsClick={onTicketsClick} onCheckoutClick={onCheckoutClick} />
    )}
  </div>
)
