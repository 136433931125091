import {withTranslation} from '@wix/yoshi-flow-editor'
import {DetailsPageAppProps} from '../../app/interfaces'
import {connect} from '../../runtime-context/context'
import {SeatingPlanRuntimeProps} from './interfaces'
import {SeatingPlan as Presentation} from './seating-plan'

const mapRuntime = ({
  state: {seating, tickets, selectedTickets},
  actions: {selectTicket, unselectTicket},
}: DetailsPageAppProps): SeatingPlanRuntimeProps => ({
  plan: seating.plan,
  tickets,
  selectedTickets,
  selectTicket,
  unselectTicket,
})

export const SeatingPlanModal = connect<{}, SeatingPlanRuntimeProps>(mapRuntime)(withTranslation()(Presentation))
export * from './interfaces'
