import * as React from 'react'
import {Button} from 'wix-ui-tpa/Button'
import {IconButton} from 'wix-ui-tpa/IconButton'
import CloseSmall from 'wix-ui-icons-common/on-stage/CloseSmall'
import DeleteSmall from 'wix-ui-icons-common/on-stage/DeleteSmall'
import {useTranslation} from '@wix/yoshi-flow-editor'
import s from './ticket-info.scss'
import {TicketInfoProps, LabelWithValueProps} from '.'

export const TicketInfo = ({
  sector,
  seat,
  row,
  ticketName,
  ticketPrice,
  fees,
  onAddClick,
  onCloseClick,
  onRemoveClick,
}: TicketInfoProps) => {
  const {t} = useTranslation()
  return (
    <div className={s.container}>
      {onCloseClick ? <IconButton className={s.headerButton} icon={<CloseSmall />} onClick={onCloseClick} /> : null}
      {onRemoveClick ? <IconButton className={s.headerButton} icon={<DeleteSmall />} onClick={onRemoveClick} /> : null}
      <div className={s.infoContainer}>
        <LabelWithValue label={t('seatings_sector')} value={sector} />
        <LabelWithValue label={t('seatings_row')} value={row} />
        <LabelWithValue label={t('seatings_seat')} value={seat} />
      </div>
      <div>
        <div className={s.smallText}>{ticketName}</div>
        <div>{ticketPrice}</div>
        <div className={s.smallText}>{fees}</div>
      </div>
      {onAddClick ? <Button onClick={onAddClick}>{t('seatings_add')}</Button> : null}
    </div>
  )
}

const LabelWithValue = ({label, value}: LabelWithValueProps) => {
  return value ? (
    <div className={s.labelWithValue}>
      <div className={s.smallText}>{label}</div>
      <div>{value}</div>
    </div>
  ) : null
}
