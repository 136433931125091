import * as React from 'react'
import {getCheckoutSummary, getPaidPlanPercentDiscount, getTaxLabel} from '@wix/wix-events-commons-statics'
import {useTranslation} from '@wix/yoshi-flow-editor'
import s from './summary.scss'
import {Buttons} from './buttons'
import {SummaryProps, SummaryItemProps} from '.'

export const Summary = ({invoice, selectedTickets, onTicketsClick, onCheckoutClick}: SummaryProps) => {
  const {t} = useTranslation()
  const {subtotal, tax, addedFee, total, paidPlanDiscount} = getCheckoutSummary(invoice)

  return (
    <>
      <div className={s.container}>
        {selectedTickets ? (
          <>
            <SummaryItem label={t('seatings_selectedTickets', {count: selectedTickets})} value={subtotal} />
            <SummaryItem
              label={t('ticketsThankYou.paidPlan', {
                discount: getPaidPlanPercentDiscount(invoice),
              })}
              value={paidPlanDiscount}
            />
            <SummaryItem label={getTaxLabel(invoice)} value={tax} />
            <SummaryItem label={t('checkout_serviceFee')} value={addedFee} />
            <SummaryItem label={t('mobile.ticketsPicker.total')} value={total} />
          </>
        ) : (
          t('seatings_selectTickets')
        )}
      </div>
      <Buttons selectedTickets={selectedTickets} onTicketsClick={onTicketsClick} onCheckoutClick={onCheckoutClick} />
    </>
  )
}

const SummaryItem = ({label, value}: SummaryItemProps) =>
  value ? (
    <div className={s.summaryItem}>
      <div>{label}</div>
      <div>{value}</div>
    </div>
  ) : null
