import React from 'react'
import classNames from 'classnames'
import X from 'wix-ui-icons-common/X'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {ModalProps} from './interfaces'
import s from './modal.scss'

export const Modal = ({children, t, closeModal, skin, closeButtonAsButton}: ModalProps) => {
  const grey = skin === 'grey'

  return (
    <div className={classNames(s.container, {[s.grey]: grey})} style={{paddingBottom: grey ? 0 : 52}}>
      <button
        className={classNames(s.closeButtonX, 'a11yOutline', {[s.asButton]: closeButtonAsButton})}
        data-hook={DH.CLOSE_BUTTON}
        aria-label={t('accessibility:a11y.close')}
        onClick={() => closeModal()}
      >
        <X className={s.iconX} />
      </button>
      {children}
    </div>
  )
}
