import * as React from 'react'
import {Plan as PlanViewer} from '@wix/seating-viewer'
import classNames from 'classnames'
import {useViewBox} from '../../../../hooks/viewbox'
import {Controls} from './controls'
import {Legend} from './legend'
import s from './plan.scss'
import {PlanProps} from '.'

const MIN_ZOOM = 0.2
const MAX_ZOOM = 2
const ZOOM_STEP = 0.2

export const Plan = ({plan, selectedSeat, selectedSeats, legend, onSeatClick}: PlanProps) => {
  const {viewBox, zoom, changeSvgViewBox, onPointerDown, onPointerMove, onPointerUp} = useViewBox(plan)
  return (
    <>
      <div
        className={classNames(s.container, {
          [s.containerWithSelectedTicket]: selectedSeat,
          [s.containerWithSummary]: !selectedSeat && selectedSeats.length,
        })}
        onPointerDown={onPointerDown}
        onPointerUp={onPointerUp}
        onPointerLeave={onPointerUp}
        onPointerMove={onPointerMove}
      >
        <PlanViewer
          currentlyClickedSeatId={selectedSeat?.id}
          selectedSeatIds={selectedSeats.map(seat => seat.id)}
          plan={plan}
          svgViewBox={viewBox}
          onSeatClick={onSeatClick}
        />
      </div>
      <Legend selectedSeat={selectedSeat} selectedSeats={selectedSeats} legend={legend} />
      <Controls
        selectedSeat={selectedSeat}
        selectedSeats={selectedSeats}
        zoomOutDisabled={zoom === MIN_ZOOM}
        zoomInDisabled={zoom === MAX_ZOOM}
        onZoomIn={() => changeSvgViewBox({deltaScale: ZOOM_STEP})}
        onZoomOut={() => changeSvgViewBox({deltaScale: -ZOOM_STEP})}
      />
    </>
  )
}
